.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(33, 33, 33);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.know-more-link {
  position: absolute;
  width: 30px;
  bottom: 30px;
  left: 50%;
  margin-left: -15px;
  font-size: 18px;
  z-index: 1;
}

.know-more-link object {
  width: 100%;
  z-index: -1000;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(338deg) brightness(100%) contrast(103%);
  -webkit-animation: KEYFRAME-NAME 2.5s ease infinite;
  -moz-animation:    KEYFRAME-NAME 2.5s ease infinite;
  -o-animation:      KEYFRAME-NAME 2.5s ease infinite;
  animation:         KEYFRAME-NAME 2.5s ease infinite;
}

@-webkit-keyframes KEYFRAME-NAME {
  0%   { opacity: 0; transform: translate(0, 0); }
  100% { opacity: 0.8; transform: translate(0, 12px); }
}
@-moz-keyframes KEYFRAME-NAME {
  0%   { opacity: 0; transform: translate(0, 0); }
  100% { opacity: 0.8; transform: translate(0, 12px); }
}
@-o-keyframes KEYFRAME-NAME {
  0%   { opacity: 0; transform: translate(0, 0); }
  100% { opacity: 0.8; transform: translate(0, 12px); }
}
@keyframes KEYFRAME-NAME {
  0%   { opacity: 0; transform: translate(0, 0); }
  100% { opacity: 0.8; transform: translate(0, 12px); }
}

.know-more-link span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.large-bold-keyword {
  font-weight: bold;
}

.right-info-card {
  width: 65%;
  min-width: 200px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-right: 0;
  /* background-color: rgb(0, 0, 0, .1); */
  z-index: 1;
}
.left-info-card {
  width: 65%;
  min-width: 200px;
  padding-top: 120px;
  padding-bottom: 30px;
  margin-left: 0;
  margin-top: -30px;
  z-index: 0;
  /* background-color: rgba(0, 0, 0, .1); */
}

.right-info-card p, .left-info-card p {
  font-size: 18px;
  text-align: left;
  font-weight: 100;
}
